.hints {
  &-row {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;

    &__word {
      display: flex;
      padding: 10px 0;
      justify-content: center;
    }

    &__cell {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--text);
      background: var(--bg-2);
      border: 2px solid var(--bg-3);
      border-radius: 5px;
      margin: 2px;
      width: 45px;
      aspect-ratio: 1;
      font-size: 1.5rem;
      font-weight: bold;
      text-shadow: 1px 1px 1px #333;
      text-transform: uppercase;
      cursor: pointer;

      &.correct {
        color: #fff;
        background: var(--correct);
        border: 2px solid transparent !important;
      }

      &.active {
        border: 2px solid #82b1c3;
      }
    }

    &__title {
      font-weight: 500;

      &.align-center {
        text-align: center;
      }
    }

    &__hint {
      font-size: 0.8rem;
      color: #818296;
      opacity: 0.6;
    }

    &__button {
      border: none;
      padding: 8px;
      color: #fff;
      background: #82b1c3;
      border-radius: 8px;
      text-shadow: 1px 1px 1px var(--text);
      cursor: pointer;

      &:disabled {
        background: var(--absent);
        cursor: not-allowed;
      }
    }
  }
}
