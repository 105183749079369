.theme-dark {
  --text: #d0d4e8;
  --desc: #a7adc0;

  --bg-1: #232332;
  --bg-2: #363749;
  --bg-3: #414458;

  --absent: #2a2c3b;
}
