@import './win.scss';
@import './defeat.scss';
@import './word-not-found.scss';
@import './hints.scss';
@import './how-to-play.scss';
@import './settings.scss';
@import './statistics.scss';

.popup {
  &-overlay {
    background-color: rgb(107 114 128 / 0.75);
    transition: all 3s ease-in-out;
    animation: appear 0.3s ease;
  }

  &-content {
    padding: 15px;
    transition-duration: 0.15s;
    transition-property: all;
    animation: anvil 0.3s forwards;
  }
}

.modal {
  color: var(--text);
  background: var(--bg-2);
  box-shadow: 0 4px 23px 0 rgb(0 0 0 / 20%);
  border-radius: 5px;
  padding: 15px;
  max-width: 400px;
  width: 90vw;

  &-title {
    color: var(--text);
    text-align: center;
  }

  &__close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 24px;
    height: 24px;
    border: none;
    color: var(--text);
    background: transparent;
    transition: all var(--animation-speed-fast);
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }

    svg {
      fill: none;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes anvil {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0.8, 0.8);
  }
  65.5% {
    transform: scale(1.03, 1.03);
  }
  100% {
    visibility: visible;
    opacity: 1;
    transform: scale(1, 1);
  }
}
