.win-row {
  display: flex;
  justify-content: center;
  margin-top: 15px;

  &__earned {
    color: var(--success);
    font-size: 1.1rem;
    font-weight: 500;
  }
}
