$board-sizes: 4, 5, 6, 7, 8, 9, 10, 11;

@mixin board($number-of-letters) {
  display: grid;
  grid-template-columns: repeat($number-of-letters, minmax(10px, 55px));
  grid-template-rows: repeat(6, minmax(auto, 55px));
  grid-gap: 5px;
  justify-items: center;
  margin: auto;
}

.board {
  @each $size in $board-sizes {
    &-#{$size} {
      @include board($size);
    }
  }

  &__cell {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text);
    background: var(--bg-2);
    border: 2px solid var(--bg-3);
    border-radius: 5px;
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    text-shadow: 1px 1px 1px #000;
    text-transform: uppercase;

    &.correct {
      color: #fff;
      background: var(--correct);
      border: 2px solid transparent;
    }

    &.present {
      color: #fff;
      background: var(--present);
      border: 2px solid transparent;
    }

    &.absent {
      color: #fff;
      background: var(--absent);
      border: 2px solid transparent;
    }

    &.unknown {
      border-color: var(--unknown);
    }

    &.fill-animation {
      animation: onTypeCell linear;
      animation-duration: 0.35s;
    }
  }
}

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
